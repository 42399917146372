/* eslint-disable no-undef */
import React, { useEffect, useState, useContext, useCallback } from 'react'
// import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
// import QrReader from 'react-qr-scanner';

import ButtonRound from '../components/ButtonRound';
import ButtonTransparent from '../components/ButtonTransparent';

import IconBack from '../assets/img/ic-back.svg';
import IconImg from '../assets/img/ic-image.svg';

import { DataContext } from '../App';

const html5QrCode = new Html5Qrcode("camera-reader");
let cameraActive = false;
let uploadAction = false;

function ScanCoupon(props) {
    // const history = useHistory()
    // const reader = useRef(null)

    const { scanData, setScanData } = useContext(DataContext === null ? '' : DataContext)
    const [scanDataDetail, setScanDataDetail] = useState([])
    const [scanDataList, setScanDataList] = useState([])
    const [qrcodeData, setQrcodeData] = useState('')

    const [errorMsg, setErrorMsg] = useState('เกิดข้อผิดพลาด')
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
    const [modalErrorUploadIsOpen, setModalErrorUploadIsOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false);
    const [btnActive, setBtnActive] = useState(false)

    const [camObj, setCamObj] = useState([]);
    const [modalCameraIsOpen, setModalCameraIsOpen] = useState(false);
    const [modalInsertCodeIsOpen, setModalInsertCodeIsOpen] = useState(false)

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');

    const handleScanQrcode = useCallback(async (qrcode) => {
        console.log(qrcode)
        // localStorage.setItem('qrcodeData', qrcode)

        // history.push('/selectcouponconfirm')
        const res = await fetch(`https://${apiLocation}/ecp/api/user/scan_qrcode`, {
            method: 'POST',
            body: JSON.stringify({
                "qrcode": qrcode
            }),
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            // console.log(res)
            if (res.success === 0) {
                setModalErrorIsOpen(true);
                setErrorMsg(res.message? res.message : 'รหัส QR Code ไม่ถูกต้อง');
                // setIsLoaded(true);

            } else {
                const data = res;
                console.log(data)
                // console.log(JSON.stringify(data.ecp_list))
                setScanData(data)
                setScanDataDetail(data)
                setScanDataList(data.ecp_list)
                setConfirm(true)
                setQrcodeData(qrcode)
                if(cameraActive) { cameraActive=false; html5QrCode.stop(); }
                // localStorage.setItem('scanData',JSON.stringify(data))
                // window.parent.location = localStorage.getItem('windowParentLocation');
                
            }

        })
        .catch(err => {
            console.log(err);
        });
    },[accessToken, apiLocation, setScanData])

    const handleAcceptCoupon = async () => {
        
        const res = await fetch(`https://${apiLocation}/ecp/api/user/accept_coupons`, {
            method: 'POST',
            body: JSON.stringify({
                "qrcode": qrcodeData
            }),
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            if (res.success === 0) {
                setModalErrorIsOpen(true);
                // setErrorMsg('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้');
                // setIsLoaded(true);
                setConfirm(false)
                if(Object.keys(res.message).length !== 0) {
                    console.error(res)
                    if(typeof res.message === 'object') {
                        setMessage('เกิดข้อผิดพลาด ไม่สามารถยืนยันได้')
                    } else {
                        setMessage(res.message)
                    }
                } else {
                    setMessage('ไม่สามารถทำรายการได้')
                }

            } else {
                const data = res;
                console.log(data)
                closeModal()
                                
                if(localStorage.getItem('accessBy')==='query') {
                    const query = localStorage.getItem('query');
                    window.parent.location = "/scancoupon/?query="+query;
                    // window.parent.location = localStorage.getItem('windowParentLocation');
                } else {
                    window.location = '/'
                };
            }

        })
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        setBtnActive(false)
        let cameraIdSelected = JSON.parse(localStorage.getItem('cameraIdSelected'))
        console.log('cameraIdSelected: ',cameraIdSelected)

        // Check Camera
        cameraActive = false;
        Html5Qrcode.getCameras().then(devices => {
            console.log('devices: ',devices)

            // Check CameraId Selected
            if(!!cameraIdSelected) {
                // setCamObj(cameraIdSelected);
                chooseCamera(cameraIdSelected[0].id, cameraIdSelected[0].label)
            } else {
                if (devices && devices.length) {
                    // console.log(devices[0].id);
                    setCamObj(devices);
                    setModalCameraIsOpen(true);
                } 

            }
            
            
        }).catch(err => {
            console.log(err)
            setErrorMsg("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
            setModalErrorIsOpen(true);
        });


        // Upload QR Code
        const fileinput = document.getElementById('qr-input-file'); 
        fileinput.addEventListener('change', e => {
            if (e.target.files.length === 0) {
                
                return false;
            }

            const imageFile = e.target.files[0];
            // Upload QR Code
            html5QrCode.scanFile(imageFile, true)
            .then(qrCodeMessage => {
                // success, use decodedText
                console.log(qrCodeMessage);
                handleScanQrcode(qrCodeMessage)
                // history.push('/')
            })
            .catch(err => {
                // failure, handle it.
                console.log(`Error scanning file. Reason: ${err}`)
                setErrorMsg('QR Codeไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
                setModalErrorUploadIsOpen(true)
            });
        });

        return () => {
            // console.log('cameraActive',cameraActive)
            if(cameraActive) { html5QrCode.stop(); }
        }

        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleScanQrcode]);

    const checkCameraActive = () => {
        uploadAction = true
        if(cameraActive) { cameraActive=false; html5QrCode.stop(); }
    }

    function handleReload() {
        // setModalErrorIsOpen(false);
        setIsLoaded(true)
        setModalCameraIsOpen(false);
    }

    // Event input code -----------------------------------------------//
    const handleInputCode = (event) => {
        // console.log(event.target.value)
        !!event.target.value?setBtnActive(true): setBtnActive(false)
        
        setScanData(event.target.value)
        // localStorage.setItem('scanData',event.target.value)
    }

    // Event send insert code
    const sendCode = () => {

        // setScanData(scanData)
        // history.push('/')
        setModalInsertCodeIsOpen(false)
        handleScanQrcode(scanData)
    } 
    // ---------------------------------------------------------------//

    // Event close modal
    const closeModal = () => {
        setModalCameraIsOpen(false);
        setModalErrorIsOpen(false);
        setModalInsertCodeIsOpen(false);

        if(uploadAction) {
            document.getElementById('qr-input-file').value = null;
            document.getElementById('qr-canvas-visible').remove();
            document.getElementById('qr-canvas').remove();
            uploadAction = false
        }
        
        console.log(document.getElementById('qr-input-file').value)
    }
    
    // Event close modal upload
    const closeModalUpload = () => {
        setModalErrorUploadIsOpen(false);
        window.location.reload()
    }

    // Event close modal insert code
    const closeInsertCodeModal = () => {
        setModalInsertCodeIsOpen(false)
        // window.location.reload()
    }

    // Event goto home
    const closeModalConfirm = () => {
        setConfirm(false)
        window.location.reload()
    }
    
    // Event goto home
    const gotoHome = () => {
        setModalCameraIsOpen(false);
        setModalErrorIsOpen(false);
        setModalInsertCodeIsOpen(false);
        setConfirm(false)
        // history.push('/')
                
        if(localStorage.getItem('accessBy')==='query') {
            window.parent.location = localStorage.getItem('windowParentLocation');
        } else {
            window.location = '/'
        }
    }

    // Event call modal insert code
    const gotoInsertCode = () => {
        setScanData('')
        setBtnActive(false)
        setModalInsertCodeIsOpen(true)
        if(cameraActive) { cameraActive=false;  html5QrCode.stop(); }
    }

    function chooseCamera(cameraId, cameraLabel) {
        // Set Camera Id Selected
        if(!localStorage.getItem('cameraIdSelected')) {
            let cameraSelected = []
            cameraSelected.push({ id: cameraId, label: cameraLabel})
            console.log('[cameraSelected] : ',cameraSelected)

            localStorage.setItem('cameraIdSelected',JSON.stringify(cameraSelected))
        }

        // console.log(camId)
        setIsLoaded(true)
        setModalCameraIsOpen(false)
        cameraActive = true;

        html5QrCode.start(
        cameraId, 
        {
            fps: 10,    // Optional frame per seconds for qr code scanning
            qrbox: 200,  // Optional if you want bounded box UI
            aspectRatio: 1.7777778
        },
        qrCodeMessage => {
            console.log('ScanCode',qrCodeMessage);
            handleScanQrcode(qrCodeMessage)
            //   html5QrCode.stop(); 
        },
        errorMessage => {
            // parse error, ignore it.
        })
        .catch(err => {
        // Start failed, handle it.
        });
    }


  Modal.setAppElement('#root');
    return (
        <div className="scanqrcode-box">
            <div className="_header bg-gradient-blue">
                <div className="btn-back dbrScanner-btn-close" onClick={gotoHome}>
                    <img src={IconBack} alt="icon" />
                </div>
                <h3>สแกน QR</h3>
                <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
            </div>

            {/* <img className="qrscanner" src={QrScanner} alt="qrscanner"/> */}
            { isLoaded ? null : <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>}

            {/* { isLoaded ? <div id="camera-reader" className="camera-reader"></div> : null} */}

            <div className="_browsefile">
                {/* <input type='file' id='file'ref={inputFile} onChange={onIptChange} type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/> */}
                {/* <button onClick={openImageDialog}>
                    <img src={IconImg} alt="icon" />
                </button> */}
                <label htmlFor="qr-input-file" className="custom-file-upload" onClick={checkCameraActive}>
                    <img src={IconImg} alt="icon" />
                </label>
                <input type="file" id="qr-input-file" accept="image/*" style={{display: 'none'}}></input>
            </div>

            <div id="camera-reader" className="camera-reader"></div>
            <div className="_footer">
                <ButtonTransparent text={'กรอกรหัสด้วยมือ'} onClick={gotoInsertCode} />
            </div>

            <Modal 
                isOpen={modalCameraIsOpen} 
                onRequestClose={handleReload}
                shouldCloseOnOverlayClick={true}
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>กรุณาเลือกกล้องเพื่อสแกน QR Code</p>
                    <br/>
                    <ul style={{listStyle: 'none'}}>
                        { camObj.map((option, index) => (
                            <li key={option.id} style={{marginBottom: '10px'}}>
                                <ButtonRound color="blue" text={(option.label.includes('front') || option.label.includes('Front') || option.label.includes('FRONT') ? 'เปิดใช้กล้องหน้า' : (option.label.includes('back') || option.label.includes('Back') || option.label.includes('BACK') ? 'เปิดใช้กล้องหลัง' : option.label))} onClick={()=>{chooseCamera(option.id, option.label)}} />
                            </li>
                        ))}
                    </ul>
                    {/* <Link to={`/main/?query=${query}`}><BtnEllipse name="ยกเลิก"  color="grey" /></Link> */}
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errorMsg}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errorMsg}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModal}/>
                </div>
            </Modal>
            <Modal 
                isOpen={modalErrorUploadIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    {/* <p>รหัส QR Code {errorMsg}</p>
                    <p>กรุณาใช้ QR อันใหม่</p> */}
                    <p dangerouslySetInnerHTML={{__html: errorMsg}}></p>
                    <br/>
                    <ButtonRound text="ตกลง" color="blue" onClick={closeModalUpload}/>
                </div>
            </Modal>
            <Modal 
                isOpen={modalInsertCodeIsOpen} 
                className="_modal box-style modal-insertcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_insertcode-modal center">
                    <p>กรอกรหัส QR ในช่องด้านล่าง</p>
                    <input type="text" name="insertcode" value={scanData} onChange={handleInputCode} />
                    <br/>
                    <div className="btn-row">
                        <ButtonRound text="ยกเลิก" color="grey" onClick={closeInsertCodeModal}/>
                        {
                            btnActive ? 
                            <ButtonRound text="ถัดไป" color="blue" onClick={sendCode}/>
                            :
                            <ButtonRound text="ถัดไป" color="blue" style={{opacity: '0.5', pointerEvents: 'none'}} />
                        }
                        
                    </div>
                </div>
            </Modal>
            <Modal 
                isOpen={confirm}
                className="_modal box-style modal-selectcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="modal-content txt-center">
                    <h3>คุณต้องการให้ส่วนลด { !!(scanDataDetail.total_face_value) ? (scanDataDetail.total_face_value).toLocaleString('en-US') : 0 } บาท { !!scanDataDetail.total_minimum_purchase ? `(ยอดซื้อขั้นต่ำ ${scanDataDetail.total_minimum_purchase } แถว)` : null } <br/>ให้กับคุณ { scanDataDetail.gt_name } โดยแลกกับคูปอง</h3>
                    {/* {qrCodeData} */}
                    <ul className="modal-coupon-list">
                        {
                            scanDataList.map((item,i)=> {
                                return (
                                    <li key={i} style={{paddingBottom: '10px'}}>
                                        <h3>{i+1}. คูปอง {!!(item.face_value) ? (item.face_value).toLocaleString('en-US') : 0} + <span className="font-red">{!!(item.topup) ? (item.topup).toLocaleString('en-US') : 0} *</span> บาท</h3>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                    <p className="font-red">* ท๊อปอัพเพิ่มเติมจาก PMI</p>
                    <br/>
                    <div className="btn-row txt-center">
                        <ButtonRound color="grey" text="ยกเลิก" onClick={closeModalConfirm} style={{padding: '18px 1.2rem'}} />
                        <ButtonRound color="blue" text="ยืนยันและสแกนต่อ" onClick={handleAcceptCoupon} style={{padding: '18px 1.2rem'}} />
                        {/* <ButtonRound color="blue" text="ยืนยัน" onClick={handleAcceptCoupon} /> */}
                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default ScanCoupon
