import React from 'react'
import moment from 'moment'
import IconHome from '../assets/img/ic-home.svg'
import IconDiamond from '../assets/img/ic-diamond.svg'
import IconCreditCard from '../assets/img/ic-credit-card.svg'
import IconPercentage from '../assets/img/ic-percentage.svg'


function CardWs(props) {
    const { data, loading } = props

    const calcSum = (arr, prop) => {
        return arr.reduce((a,b)=>{
            return a + b[prop]
        }, 0)
    }

    const getUnique = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const getAllShopScan = () => {
        let result = getUnique(data.allscan, 'shop_name').length
        // console.log('สแกนแล้ว : ', result, ' ร้าน')

        return result
    }

    const getAllCouponValue = () => {
        let result = calcSum(data.allscan, 'topup')
        // console.log('ค่าเก็บคูปอง : ', result, ' บาท')

        return result
    }

    const getAllCouponAmount = () => {
        let result = data.allscan.length
        // console.log('รับคูปองร้านปลีก : ', result, ' ใบ')

        return result
    }

    const getAllDiscount = () => {
        let result = calcSum(data.allscan, 'face_value')
        // console.log('ลดให้ร้านปลีก : ', result, ' บาท')

        return result
    }

    const convertDateMonth = (value) => {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];
        let getDate = moment(value).format('DD')
        let getMonth = monthThArr[Number(moment(value).format('MM')) - 1]
        return  getDate + ' ' + getMonth
    }


    const checkCcb2bId = () => {
        let result = getUnique(data.allscan, 'ccb2b_coupon_id').length
        if(result > 1) {
            return 'ไม่สามารถแสดงค่า coupon_id ได้'
        } else if(result === 1 && !!data.allscan[0].ccb2b_coupon_id) {
            return '#'+data.allscan[0].ccb2b_coupon_id
        } else {
            // if(!!data.allscan[0].ccb2b_coupon_id) {
            //     return '#'+data.allscan[0].ccb2b_coupon_id
            // } else {
                return ' '
            // }
        }
    }

    const checkCcb2bExpDate = () => {
        let result = getUnique(data.allscan, 'ccb2b_exp_date').length
        if(result > 1) {
            return 'ไม่สามารถระบุได้'
        } else if(result === 1 && !!data.allscan[0].ccb2b_exp_date) {
            return convertDateMonth(data.allscan[0].ccb2b_exp_date)
        }  else {
            // if(!!data.allscan[0].ccb2b_exp_date) {
            //     return convertDateMonth(data.allscan[0].ccb2b_exp_date)
            // } else {
                return '-'
            // }
        }
    }

    const checkCcb2bUseDate = () => {
        let result = getUnique(data.allscan, 'ccb2b_use_date').length
        if(result > 1) {
            return 'ไม่สามารถระบุได้'
        } else {
            if(!!data.allscan[0].ccb2b_use_date) {
                return convertDateMonth(data.allscan[0].ccb2b_use_date)
            } else {
                return '-'
            }
        }
    }

    const getAmountExpireDay = (value) => {
        if(!!value) {
            let expDate = moment(value).add(1,'days')
            let currentDate = moment()
            let amountExpireDay = expDate.diff(currentDate, 'days')
            let yesterday = expDate.isBefore(currentDate)
    
            // console.log('yesterday:',yesterday,' expireDat: ',value ,' = ', amountExpireDay)
    
            if(amountExpireDay > 0 && amountExpireDay <= 7 && !yesterday ) {
                return ( 
                    <div className="amount-expire-day">
                        <p>{ `หมดอายุในอีก ${amountExpireDay} วัน` }</p>
                    </div>
                )
            } else if(amountExpireDay === 0 && !yesterday) {
                return ( 
                    <div className="amount-expire-day">
                        <p>{ `หมดอายุภายในวันนี้` }</p>
                    </div>
                )
            } else {
                return null
            }
    
        } else {
            return null
        }
        
       
    }

    return (
        <div className="pointer-events-none">
            {
                loading ? 
                <div className={`ws-card status-none`}>
                    <div className={`_left`}>
                        <div className="_header flex items-baseline">
                            <h1>คูปองร้านปลีก</h1>
                            {/* <p>ร้านปลีก</p> */}
                        </div>
                        <div className="_body summary-list">
                            <div className="summary-item flex items-center">
                                <img src={IconPercentage} alt="icon" />
                                <p className="text-left">ลดให้ร้านปลีก - บาท</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconDiamond} alt="icon" />
                                <p className="text-left">ค่าเก็บคูปอง - บาท</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconCreditCard} alt="icon" />
                                <p className="text-left">รับคูปองร้านปลีก - ใบ</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconHome} alt="icon" />
                                <p className="text-left">สแกนแล้ว - ร้าน</p>
                            </div>
                        </div>
                    </div>
                    <div className={`_right txt-hilight text-center`}>
                        <p className="txt-gray text-1">&nbsp;</p>
                        <div className="_body">
                            <h2 className="font-sriracharegular text-1-1">ส่วนลดที่ได้รับ</h2>
                            <h1 className="font-noricanregular"> - </h1>
                            <h2 className="font-sriracharegular text-1-1">บาท</h2>
                        </div>
                        <div className="_footer">
                            <div className="left text-1-1">
                                <p>สะสมถึงวันที่</p>
                                <p>-</p>
                            </div>
                            <div className="right text-1-1">
                                <p>หมดอายุ</p>
                                <p>-</p>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={`ws-card status-${data.card_status}`}>
                    <div className={`_left`}>
                        <div className="_header flex items-baseline">
                            <h1>คูปองร้านปลีก</h1>
                            {/* <p>ร้านปลีก</p> */}
                        </div>
                        <div className="_body summary-list">
                            <div className="summary-item flex items-center">
                                <img src={IconPercentage} alt="icon" />
                                <p className="text-left">ลดให้ร้านปลีก { getAllDiscount().toLocaleString('en-US') } บาท</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconDiamond} alt="icon" />
                                <p className="text-left">ค่าเก็บคูปอง { getAllCouponValue().toLocaleString('en-US') } บาท</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconCreditCard} alt="icon" />
                                <p className="text-left">รับคูปองร้านปลีก { getAllCouponAmount().toLocaleString('en-US') } ใบ</p>
                            </div>
                            <div className="summary-item flex items-center">
                                <img src={IconHome} alt="icon" />
                                <p className="text-left">สแกนแล้ว { getAllShopScan().toLocaleString('en-US') } ร้าน</p>
                            </div>
                        </div>
                    </div>
                    <div className={`_right txt-hilight text-center`}>
                        {
                            data.card_status === 'ready' || data.card_status==='ccb2b-ready' ?
                               getAmountExpireDay(data.allscan[0].ccb2b_exp_date)
                            : null
                        }
                        {
                            data.card_status === 'current' ? <p className="txt-gray text-1">&nbsp;</p> : <p className="txt-gray text-1">{ checkCcb2bId() }</p>
                        }
                        {/* {
                            checkCcb2bId()
                        } */}
                        <div className="_body">
                            <h2 className="font-sriracharegular text-1-1">ส่วนลดที่ได้รับ</h2>
                            <h1 className="font-noricanregular"> { (getAllCouponValue() + getAllDiscount()).toLocaleString('en-US') }</h1>
                            <h2 className="font-sriracharegular text-1-1">บาท</h2>
                        </div>
                        <div className="_footer">
                            {
                                data.card_status==='current' || data.card_status==='ccb2b-current' ?
                                <div className="left text-1-1">
                                    <p>สะสมถึงวันที่</p>
                                    <p>{ !!data.endDate?convertDateMonth(data.sasom_tung_wan_tee):'-' }</p>
                                </div>
                                : data.card_status==='ready' || data.card_status==='ccb2b-ready' ?
                                <div className="left text-1-1">
                                    <p className="text-bold">พร้อมใช้งาน</p>
                                </div>
                                : data.card_status==='expired' || data.card_status==='ccb2b-expired' ?
                                <div className="left text-1-1">
                                    <p className="text-bold">หมดอายุ</p>
                                </div>
                                : data.card_status==='used' || data.card_status==='ccb2b-used' ?
                                <div className="left text-1-1">
                                    <p>ใช้งานไปแล้ว</p>
                                    <p>{ checkCcb2bUseDate() }</p>
                                </div>
                                : data.card_status==='processing' || data.card_status==='ccb2b-processing' ?
                                <div className="left text-1-1">
                                    <p>กำลังดำเนินการ</p>
                                </div>
                                : 
                                <div className="left text-1-1">
                                    <p>-</p>
                                </div>
                            }
                            
                            <div className="right text-1-1">
                                <p>หมดอายุ</p>
                                <p>{ checkCcb2bExpDate() }</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default CardWs