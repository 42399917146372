import React, { useState, useEffect } from 'react'
import moment from 'moment';

import BgCouponDisable from '../assets/img/bg-coupon-disable.png';
import BgCouponDisableVip from '../assets/img/bg-coupon-disable-vip.png';
import BgSelectInvisible from '../assets/img/ic-select-invisible.png';

function CouponBlackCardView(props) {
    const { dataItem, dataGroupType } = props
    const [windowW, setWindowW] = useState(window.innerWidth)

    const resizeWindow = () => {
        setWindowW(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", resizeWindow);
        
        return () => {
            resizeWindow()
        }
    }, [windowW])

    let BgImage = ''
    let BgIconInvisible = ''

    BgImage = dataItem.click_to_collect === 1 && dataItem.sf_point > 0 ? BgCouponDisableVip : BgCouponDisable 
    BgIconInvisible = BgSelectInvisible

    function showEndDate(endDateVal) {
        const monthThArr = ['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];
        const end = endDateVal
        
        const endD = moment(end).format('DD');
        const endM = moment(end).format('M');
        const endY = moment(end).format('YYYY');
        const endTime = moment(end).format(' HH:mm')

        const valueEnddate = endD+' '+monthThArr[endM - 1]+' '+(Number(endY)+543)+' '+endTime;
        
        return valueEnddate;
    }

    return (
        <div className="couponcard _black" style={{backgroundImage: 'url('+BgImage+')', minHeight: (windowW/2.87)+'px' }}>
            <div className="_content">
                <div className="_row">
                    <div className="_left">
                        <h3>{
                            // dataGroupType === 'gt' ? 'คูปองส่วนลด' /*'ใช้กับร้านส่ง'*/ : dataGroupType === 'ws' ? 'คูปองส่วนลด' : 'คูปองส่วนลด'
                            dataItem.click_to_collect === 1 && dataItem.sf_point > 0 ? 'คูปอง VIP' : 'คูปองส่วนลด'
                        }</h3>
                    </div>
                    <div className="_right">
                        <img src={BgIconInvisible} alt="icon" />
                    </div>
                </div>
                {
                    (!!dataItem.wording && dataGroupType === 'gt') || (!!dataItem.wording && dataGroupType === 'ws' && dataItem.ecp_status === 'unused') ?
                    <div className="_row">
                        <div className="_left" style={{whiteSpace: 'nowrap'}}>
                            {dataItem.wording}
                            {/* <h3>ทดสอบขึ้นขั้นตอนสูงสุดเพิ่มข้อความ</h3> */}
                        </div>
                        <div className="_right"></div>
                    </div>
                    : null
                }
                <div className="_row">
                    <div className="_left">
                    <h1>{
                            dataGroupType === 'ws' ? 
                            !!dataItem.ecp_value ? dataItem.ecp_value.toLocaleString('en-US') : 0
                            :
                            !!dataItem.face_value ? dataItem.face_value.toLocaleString('en-US') : 0
                            // (dataGroupType === 'ws' && dataItem.ecp_status === 'used') || (dataGroupType === 'ws' && dataItem.ecp_status === 'redeemed') ? 
                            //     !!(dataItem.face_value + dataItem.topup) ? (dataItem.face_value + dataItem.topup).toLocaleString('en-US') : 0
                            //     :
                            //     !!dataItem.face_value ? dataItem.face_value.toLocaleString('en-US') : 0
                        } <span>บาท</span></h1>
                    </div>
                    <div className="_right">
                        <p>รหัส {dataItem.ecp_id}</p>
                    </div>
                </div>
            </div>
            <div className="_bottom">
                {
                    dataItem.end_date === null || dataItem.end_date === '' ? <p>&nbsp;</p> 
                    :
                    // ecp_status : expired, used, redeemed
                    <p>
                        { (dataItem.ecp_status === 'used' && dataGroupType === 'gt') || dataItem.ecp_status === 'redeemed' ? 'ใช้เมื่อวันที่' : dataItem.ecp_status === 'expired' ? 'หมดอายุวันที่' : ''} 
                        { (dataItem.ecp_status === 'used' && dataGroupType === 'gt') ? showEndDate(dataItem.use_date) : dataItem.ecp_status === 'expired' ? showEndDate(dataItem.end_date) : dataItem.ecp_status === 'redeemed' ? showEndDate(dataItem.redeem_date):''}
                    </p>
                    
                    // <p>{dataItem.ecp_status === 'used' || dataItem.ecp_status === 'redeemed' ? 'ใช้เมื่อวันที่' : dataItem.ecp_status === 'expired' ? 'หมดอายุวันที่' : ''} { dataItem.ecp_status === 'used'? showEndDate(dataItem.use_date) : showEndDate(dataItem.end_date)}</p>
                    // <p>{dataItem.contentbottom} {moment(dataItem.date).format("DD MMMM YYYY")}</p>
                }
            </div>
        </div>
    )
}

export default CouponBlackCardView
