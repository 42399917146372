import React, { useEffect, useState, useCallback } from 'react'
import Select from 'react-select'
import moment from 'moment';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function WsShopSummaryItem(props) {
    const { data } = props
    const [shopItem, setShopItem] = useState([])
    const [shopNameList, setShopNameList] = useState([])
    const [filterList, setFilterList] = useState([])

    const convertDateMonthYearTime = (value) => {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

        let date = moment(value).format('DD')
        let month = monthThArr[ Number(moment(value).format('MM')) - 1 ]
        let year = Number(moment(value).format('YYYY')) + 543
        let time = moment(value).format('HH:mm:ss')

        return date + ' ' + month + ' ' + year + ' เวลา ' + time + ' น.'
    }

    // Remove Duplicate Arry Value
    const removeDuplicate = (arr) => [...new Set(arr)]

    // Check Shop Name
    const checkShopName = useCallback( () => {
        // Get Shop Name
        let resultShopName = []
        for(let i=0; i< data.allscan.length; i++) {
            resultShopName.push( data.allscan[i].shop_name) // e.g. ['a shop','a shop','b shop']
        }

        // Remover Duplicate Shop Name
        let resultShopNameAll = removeDuplicate(resultShopName) // e.g. ['a shop','b shop']

        // Sort Shop Name
        let sumArr = []
        for(let i=0; i<resultShopNameAll.length; i++) {

            let sumValue = 0
            let sumName = ''

            for(let j=0; j<data.allscan.length; j++) {
                if(data.allscan[j].shop_name === resultShopNameAll[i]) {
                    sumValue += data.allscan[j].face_value + data.allscan[j].topup
                }
            }
            sumName = resultShopNameAll[i]
            sumArr.push({ name: sumName, value: sumValue })
        }

        // console.log('sumArrSort: ', sumArr.sort((a,b)=> a.value < b.value ? 1 : -1))

        // Sort Order by DESC
        let sumArrSort = sumArr.sort((a,b)=> a.value < b.value ? 1 : -1)

        // After Sort, Filter Shop Name Only ( Order by DESC)
        let resultShopNameAllSort = []
        let shopNameOptionArr = []
        shopNameOptionArr.push({ value: 'all', label: 'ทั้งหมด'})
        for(let k=0; k<sumArrSort.length; k++) {
            resultShopNameAllSort.push(sumArrSort[k].name)
            shopNameOptionArr.push({ value: sumArrSort[k].name, label: sumArrSort[k].name})
        }

        setFilterList(shopNameOptionArr)

        // setFilterList(resultShopNameAllSort)

        if(resultShopNameAllSort.length) {
            setShopItem(resultShopNameAllSort)
            setShopNameList(resultShopNameAllSort)
        } else {
            setShopItem([])
            setShopNameList([])
        }
    },[data.allscan])

    useEffect(() => {
        checkShopName()
        console.log('Data AllScan: ',data.allscan)
    
        return () => {
            
        }
    }, [checkShopName, data])
    
    
    const genListItem = (item,i) => {
        let shopName = item
        let shopDetailArr = []

        let receiveDiscount = 0;
        let retailDiscount = 0;
        let receiveCoupon = 0;
        let totalCouponValue = 0;

        for(let j=0; j<data.allscan.length; j++) {

            if(data.allscan[j].shop_name === item) {
                receiveDiscount += (data.allscan[j].face_value + data.allscan[j].topup)
                retailDiscount += data.allscan[j].face_value
                receiveCoupon += 1
                totalCouponValue += data.allscan[j].topup

                // Insert Shop Name to Array
                // shopNameArr.push(data.allscan[j].shop_name)
                shopDetailArr.push({ ecpId: data.allscan[j].ecp_id, time: data.allscan[j].use_date, value: data.allscan[j].face_value + data.allscan[j].topup})
                
            }
        }

        return (
            <div className="item" key={i}>
                <div className="_body">
                    <div className="row">
                        <div className="_left">ส่วนลดที่ได้รับจาก ร้าน { shopName }</div>
                        <div className="_right"><span>{ !!receiveDiscount ? receiveDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ลดให้ร้านปลีก ร้าน { shopName }</div>
                        <div className="_right"><span>{ !!retailDiscount ? retailDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ค่าเก็บคูปอง</div>
                        <div className="_right"><span>{ !!totalCouponValue ? totalCouponValue.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">รับคูปองร้านปลีก</div>
                        <div className="_right"><span>{ !!receiveCoupon ? receiveCoupon.toLocaleString('en-US') : 0 }</span> ใบ</div>
                    </div>
                    {
                        shopDetailArr.length ?
                        <Accordion allowZeroExpanded>
                            <AccordionItem key={i}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>&nbsp;</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {
                                        shopDetailArr.map((item,i) => {
                                            return (
                                                <div key={i} className="row drilldown">
                                                    <div className="_left">คูปอง { item.ecpId } <br/><span className="datetime">{ convertDateMonthYearTime(item.time) }</span></div>
                                                    <div className="_right"><span>{ !!item.value ? item.value.toLocaleString('en-US') : 0 }</span> บาท</div>
                                                </div>
                                            )
                                        })
                                    }
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        : null
                    }
                </div>
            </div>
        )
    }

    const filterShopName = (e) => {
        console.log('filter: ',e.value)
        
        let name = (e.value === 'all') ? shopNameList : [e.value]

        // let name =  e.value==='all' ? filterList : [e.value]
        setShopItem(name)
        genListItem(name,0)
    }

    return (
        <div className="ws-summary">
            {
                shopNameList.length ?
                    <div className="ws-filter flex items-center">
                        <label>ร้านค้า </label>
                        <Select
                            onChange={filterShopName}
                            options={filterList}
                            className="react-select-fillter"
                            placeholder={'ทั้งหมด'}
                            noOptionsMessage={()=> 'ไม่พบข้อมูล'}
                        />
                    </div>
                : null
            }
            {
                shopItem.length ? 
                    shopItem.map((item,i)=> genListItem(item,i) )
                : <div className="text-center my-2"><p>ไม่พบข้อมูล</p></div>
            }
        </div>
    )
}

export default WsShopSummaryItem