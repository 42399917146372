import React, { useEffect, useCallback, useState } from 'react'
import moment from 'moment'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function WsWeekSummaryItem(props) {
    const { data } = props
    const [dateItem, setDateItem] = useState([])


    // Convert date to unix timestamp for sort date
    let dateUnix = []
    for(let d=0; d<dateItem.length; d++) {
        dateUnix.push(moment(dateItem[d]).valueOf())
    }

    // Sort date unix Order by DESC
    let dateItemSort = dateUnix.sort((a,b)=> a < b ? 1 : -1)

    // Convert date unix to date
    let dataItemArr = []
    for(let e=0; e<dateItemSort.length; e++) {
        dataItemArr.push(moment(dateItemSort[e]).format('YYYY-MM-DD'))
    }

    const convertDateMonthYear = (value) => {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

        let date = moment(value).format('DD')
        let month = monthThArr[ Number(moment(value).format('MM')) - 1 ]
        let year = Number(moment(value).format('YYYY')) + 543

        return date + ' ' + month + ' ' + year
    }

    // Remove Duplicate Arry Value
    const removeDuplicate = (arr) => [...new Set(arr)]

    const checkUseDate = useCallback( () => {
        let resultUseDate = []
        for(let i=0; i< data.allscan.length; i++) {
            resultUseDate.push( moment(data.allscan[i].use_date).format('YYYY-MM-DD')) // e.g. ['2023-03-01','2023-03-01','2023-03-02']
        }

        let resultUseDateAll = removeDuplicate(resultUseDate) // e.g. ['2023-03-01','2023-03-02']

        if(resultUseDateAll.length) {
            setDateItem(resultUseDateAll)
        } else {
            setDateItem([])
        }

    },[data.allscan])


    const genListItem = (item, i) => {
        let shopNameArr = []
        let shopDetailArr = []
        
        let receiveDiscount = 0;
        let retailDiscount = 0;
        let scanned = 0;
        let receiveCoupon = 0;
        let totalCouponValue = 0;

        for(let j=0; j<data.allscan.length; j++) {

            if(data.allscan[j].use_date.slice(0,10) === item) {
                receiveDiscount += (data.allscan[j].face_value + data.allscan[j].topup)
                retailDiscount += data.allscan[j].face_value
                receiveCoupon += 1
                totalCouponValue += data.allscan[j].topup

                // Inser Shop Name to Array
                shopNameArr.push(data.allscan[j].shop_name)
                shopDetailArr.push({ name: data.allscan[j].shop_name, value: data.allscan[j].face_value + data.allscan[j].topup})
                
            }
        }

        // Get Amount Shop Name
        let shopNameListArr = removeDuplicate(shopNameArr)
        scanned = shopNameListArr.length

        // Get Total (face_value + topup) of each shop name
        let totalEachShopName = []
        for(let k=0; k<shopNameListArr.length; k++) {
            let total = 0
            for(let l=0; l<shopDetailArr.length; l++) {
                if(shopNameListArr[k] === shopDetailArr[l].name) {
                    total += shopDetailArr[l].value
                }

            }
            totalEachShopName.push({ name: shopNameListArr[k], value: total})

        }

        // console.log('item', item)
        // console.log('shopDetailArr: ',shopDetailArr)
        // console.log('shopNameListArr: ',shopNameListArr)
        // console.log('totalEachShopName: ',totalEachShopName)
        // console.log('------------------------------')

        return (
            <div className="item" key={i}>
                <h1>{convertDateMonthYear(item)}</h1>
                <div className="_body">
                    <div className="row">
                        <div className="_left">ส่วนลดที่ได้รับ</div>
                        <div className="_right"><span>{ !!receiveDiscount ? receiveDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ลดให้ร้านปลีกรวม</div>
                        <div className="_right"><span>{ !!retailDiscount ? retailDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ค่าเก็บคูปอง</div>
                        <div className="_right"><span>{ !!totalCouponValue ? totalCouponValue.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">รับคูปองร้านปลีก</div>
                        <div className="_right"><span>{ !!receiveCoupon ? receiveCoupon.toLocaleString('en-US') : 0 }</span> ใบ</div>
                    </div>
                    <div className="row">
                        <div className="_left">สแกนแล้ว</div>
                        <div className="_right"><span>{ !!scanned ? scanned.toLocaleString('en-US') : 0 }</span> ร้าน</div>
                    </div>
                    {
                        totalEachShopName.length ?
                        <Accordion allowZeroExpanded>
                            <AccordionItem key={i}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>&nbsp;</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {
                                            totalEachShopName.map((item,i) => {
                                                return (
                                                    <div key={i} className="row drilldown">
                                                        <div className="_left">ร้าน { item.name } </div>
                                                        <div className="_right"><span>{ !!item.value ? item.value.toLocaleString('en-US') : 0 }</span> บาท</div>
                                                    </div>
                                                )
                                            })
                                    }
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        : null
                    }
                </div>
            </div>
        )
    }


    useEffect(() => {
        checkUseDate()
        console.log('Data AllScan: ',data.allscan)
        
        return () => {
            
        }
    }, [checkUseDate, data])
    


    return (
        <div className="ws-summary">
            {
                dataItemArr.length ? 
                    dataItemArr.map((item,i)=> genListItem(item,i) )
                : <div className="text-center my-2"><p>ไม่พบข้อมูล</p></div>
            }
        </div>
    )
}

export default WsWeekSummaryItem