import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment';
import Select from 'react-select'
// import moment from 'moment';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function WsPeddlerSummaryItem(props) {
    const { data } = props
    const [peddlerItem, setPeddlerItem] = useState([])
    const [peddlerNameList, setPeddlerNameList] = useState([])
    const [filterList, setFilterList] = useState([])

    const convertDateMonthYearTime = (value) => {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];

        let date = moment(value).format('DD')
        let month = monthThArr[ Number(moment(value).format('MM')) - 1 ]
        let year = Number(moment(value).format('YYYY')) + 543
        // let time = moment(value).format('HH:mm:ss')

        return date + ' ' + month + ' ' + year // + ' เวลา ' + time + ' น.'
    }

    // Remove Duplicate Arry Value
    const removeDuplicate = (arr) => [...new Set(arr)]

    // Check Shop Name
    const checkPeddlerName = useCallback( () => {
        // Get Shop Name
        let resultPeddlerName = []
        for(let i=0; i< data.allscan.length; i++) {
            resultPeddlerName.push( data.allscan[i].peddler_name) // e.g. ['a shop','a shop','b shop']
        }

        // Remover Duplicate Shop Name
        let resultPeddlerNameAll = removeDuplicate(resultPeddlerName) // e.g. ['a shop','b shop']

        // Sort Shop Name
        let sumArr = []
        for(let i=0; i<resultPeddlerNameAll.length; i++) {

            let sumValue = 0
            let sumName = ''

            for(let j=0; j<data.allscan.length; j++) {
                if(data.allscan[j].peddler_name === resultPeddlerNameAll[i]) {
                    sumValue += data.allscan[j].face_value + data.allscan[j].topup
                }
            }
            sumName = resultPeddlerNameAll[i]
            sumArr.push({ name: sumName, value: sumValue })
        }

        // console.log('sumArrSort: ', sumArr.sort((a,b)=> a.value < b.value ? 1 : -1))

        // Sort Order by DESC
        let sumArrSort = sumArr.sort((a,b)=> a.value < b.value ? 1 : -1)

        // After Sort, Filter Shop Name Only ( Order by DESC)
        let resultPeddlerNameAllSort = []
        let peddlerNameOptionArr = []
        peddlerNameOptionArr.push({ value: 'all', label: 'ทั้งหมด'})
        for(let k=0; k<sumArrSort.length; k++) {
            resultPeddlerNameAllSort.push(sumArrSort[k].name)
            peddlerNameOptionArr.push({ value: sumArrSort[k].name, label: (sumArrSort[k].name==='')?'สแกนเอง':sumArrSort[k].name})
        }

        setFilterList(peddlerNameOptionArr)

        // setFilterList(resultPeddlerNameAllSort)

        if(resultPeddlerNameAllSort.length) {
            setPeddlerItem(resultPeddlerNameAllSort)
            setPeddlerNameList(resultPeddlerNameAllSort)
        } else {
            setPeddlerItem([])
            setPeddlerNameList([])
        }
    },[data.allscan])

    useEffect(() => {
        checkPeddlerName()
        console.log('Data AllScan: ',data.allscan)
    
        return () => {
            
        }
    }, [checkPeddlerName, data])
    
    
    const genListItem = (item,i) => {
        let peddlerName = item
        let shopNameArr = []
        // let shopDetailArr = []
        let useDateArr = []
        let useDateDetailArr = []

        let receiveDiscount = 0;
        let retailDiscount = 0;
        let scanned = 0;
        let receiveCoupon = 0;
        let totalCouponValue = 0;

        for(let j=0; j<data.allscan.length; j++) {

            if(data.allscan[j].peddler_name === item) {
                receiveDiscount += (data.allscan[j].face_value + data.allscan[j].topup)
                retailDiscount += data.allscan[j].face_value
                receiveCoupon += 1
                totalCouponValue += data.allscan[j].topup

                // Insert Shop Name to Array// Inser Shop Name to Array
                shopNameArr.push(data.allscan[j].shop_name)
                useDateDetailArr.push({ name: data.allscan[j].shop_name, value: data.allscan[j].face_value + data.allscan[j].topup})
                
                useDateArr.push(moment(data.allscan[j].use_date).format('YYYY-MM-DD'))
                useDateDetailArr.push({ name: moment(data.allscan[j].use_date).format('YYYY-MM-DD'), value: data.allscan[j].face_value + data.allscan[j].topup})
            }
        }


        let shopNameListArr = removeDuplicate(shopNameArr)
        scanned = shopNameListArr.length

        let useDateListArr = removeDuplicate(useDateArr)
        
        // Convert Date to milliseconds for sort
        let useDateArrConvert = []
        for(let i=0; i<useDateListArr.length; i++) {
            useDateArrConvert.push(moment(useDateListArr[i]).valueOf())
        }

        // Sort Order by DESC
        let useDateListArrSort = useDateArrConvert.sort((a,b)=> a < b ? 1 : -1)

        // Revert Date milliseconds to Date format 
        let useDateArrRevert = []
        for(let i=0; i<useDateListArrSort.length; i++) {
            useDateArrRevert.push(moment(useDateListArrSort[i]).format('YYYY-MM-DD'))
        }
        
        // Get Total (face_value + topup) of each shop name
        let totalEachShopName = []
        for(let k=0; k<useDateArrRevert.length; k++) {
            let total = 0
            for(let l=0; l<useDateDetailArr.length; l++) {
                if(useDateArrRevert[k] === useDateDetailArr[l].name) {
                    total += useDateDetailArr[l].value
                }

            }
            totalEachShopName.push({ name: convertDateMonthYearTime(useDateArrRevert[k]), value: total})

        }

        //  console.log('item', item)
        // console.log('shopDetailArr: ',shopDetailArr)
        // console.log('shopNameListArr: ',shopNameListArr)
        // console.log('totalEachShopName: ',totalEachShopName)
        // console.log('------------------------------')


        return (
            <div className="item" key={i}>
                <div className="_body">
                    <div className="row">
                        <div className="_left">ส่วนลดที่ได้รับ{ (!!peddlerName) ? 'จาก '+peddlerName : 'จาก การสแกนเอง' }</div>
                        <div className="_right"><span>{ !!receiveDiscount ? receiveDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ลดให้ร้านปลีกรวม </div>
                        <div className="_right"><span>{ !!retailDiscount ? retailDiscount.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">ค่าเก็บคูปอง</div>
                        <div className="_right"><span>{ !!totalCouponValue ? totalCouponValue.toLocaleString('en-US') : 0 }</span> บาท</div>
                    </div>
                    <div className="row">
                        <div className="_left">รับคูปองร้านปลีก</div>
                        <div className="_right"><span>{ !!receiveCoupon ? receiveCoupon.toLocaleString('en-US') : 0 }</span> ใบ</div>
                    </div>
                    <div className="row">
                        <div className="_left">สแกนแล้ว</div>
                        <div className="_right"><span>{ !!scanned ? scanned.toLocaleString('en-US') : 0 }</span> ร้าน</div>
                    </div>
                    {
                        totalEachShopName.length ?
                        <Accordion allowZeroExpanded>
                            <AccordionItem key={i}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>&nbsp;</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    {
                                        totalEachShopName.map((item,i) => {
                                            return (
                                                <div key={i} className="row drilldown">
                                                    <div className="_left">วันที่ { item.name } </div>
                                                    <div className="_right"><span>{ !!item.value ? item.value.toLocaleString('en-US') : 0 }</span> บาท</div>
                                                </div>
                                                )
                                        })
                                    }
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                        : null
                    }
                </div>
            </div>
        )
    }

    const filterPeddlerName = (e) => {
        console.log('filter: ',e.value)
        
        let name = (e.value === 'all') ? peddlerNameList : [e.value]

        // let name =  e.value==='all' ? filterList : [e.value]
        setPeddlerItem(name)
        genListItem(name,0)
    }

    return (
        <div className="ws-summary">
            {
                peddlerNameList.length > 1 ?
                    <div className="ws-filter flex items-center">
                        <label>ผู้ส่ง </label>
                        <Select
                            onChange={filterPeddlerName}
                            options={filterList}
                            className="react-select-fillter"
                            placeholder={'ทั้งหมด'}
                            noOptionsMessage={()=> 'ไม่พบข้อมูล'}
                        />
                    </div>
                : null
            }
            {
                peddlerItem.length ? 
                    peddlerItem.map((item,i)=> genListItem(item,i) )
                : <div className="text-center my-2"><p>ไม่พบข้อมูล</p></div>
            }
        </div>
    )
}

export default WsPeddlerSummaryItem