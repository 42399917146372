import React from 'react'

function ButtonTransparent(props) {
    const { text, onClick } = props
    return (
        <button className={`btn-round bg-transparent`} onClick={onClick}>
            <h4 className="font-white">{ text }</h4>
        </button>
    )
}

export default ButtonTransparent
