import React, { useEffect, useState } from 'react';
import Modal from 'react-modal'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import './App.scss';
import './Ws.scss';
import Main from './pages/Main';
import ButtonRound from './components/ButtonRound';

// import Mockup from './Mockup.json'

const DataContext = React.createContext();
// GT query
// const queryFinal = "0N%2FjpaeZsyHSSIE%2BCMbrmAoxeEG8UDFYmg%2BD3gJbRCG7tI1C7VxpuPt2t5XzEqFs2sEuJ9x%2BTGpW8S2%2Bzd2T6uw5%2Bsc2Nu9QAc4uNF%2F1b7KoZK8mTxRqjIbIGTFCYex8zmb9npdLzeIdFJ3qLPIDcJ5V0QzCEqEGfG9n3%2FPvlCtR0xxyDFdpn3Ql6W%2BtOmo64fKMd3oZx%2BkzXjLUN0feKWzmwiLQzpvoWO%2B8bQlFaKvzEYVC2ylx%2BttVQCGyNerLe1FyoZXNnCV8MVCYnurgl5mU1WZq7WoIoOYHbcOdIXX53dsryCnQkAE1Q7mtqlePLHRp5rmAppmO7jspDyPgwH9OxzQQkp9CZOxyE2RgHaZmOO0w7pI1YEweSfHDUT78IsCSPwho09VpjH9tNubk%2BFEQoGpGJbUL0ezpilhHsef4f9TadxXWpns2Lh718WwT%2BxEgOl25FAuomQxckLVZGlaGu6gGe9Y0u2p8ZG7epspNNtu%2BHgSYEFf6IccK7Ar4OzKkGWC4VGkVkQ%2BR49A3MUNNDNVqbm%2Fp7FLSNIHIS%2Fc6O%2F0dEhQYw36uYPkmAkos1GMULgdt%2B4%2BtDQoFWyQzbiXWAUjQP2q%2FPHessEksGANAjtN1s88lNX4pV0nh2KcWp0fKGL56EJlpktcpPAkNca4KZrEvpIFL5z8hc2RUTLKDnkNNm1LxpMRvc2gVVhrz9SJQpXjote1izPZSLh6uvIrcrdprOpWwT17%2BS%2BMGTcLivJ8aHgQGY1s8NURjH%2FhEcYwxwAckGpINeWhFBgRg6Sj02hjnJpcKFPki6YReGc7M7Kl4joii3heAtUHxEDqM%2F2ZynV9MlCHXgDCdDTF01J0sdMsJBTCAJBZePqiHt28z2Fng%2BZevWUuLRa5tfsJylvdf6HfUAX5oTfpyaOcwbzTtX9%2BmEMBhHjzqC%2F%2FW4PFpnthwrZSVHJbhzoD8%2BQONRdupZ8CMVakZ1z%2FzEufdfEDVHQVFQz5lcI8DHxYYlaRxAPmNFgATYvAZ0r6CZBUsJp81sruLS6fd3TvArusXI8LmUL0D4tW0RUpA1ekghW4wbEE9FmFEY0RZkp%2BzXBmEbFdlxox5spvoYpLnM9Q6uXCXpSHimKFFKNY%2BujO3IIemutXNAeBoPDip13OWUd%2Fo"
// WS query
// const queryFinal = "0N/jpaeZsyHSSIE+CMbrmABKssCcKHGvcxls3xEgHCTTKMP9aT/SNiQ8xD8aiG/sa4L3i4HyfHeaDpcJIC+O30l+tgQwE+N0v67exAM7ti12FpCCMtW/KnBdnSAXzMyvzWIAzJKQiWjOQ6iYBNzDcGgITJLr7uZ57jkNgBGaAgmEMyPojDQKP1ItFqRvQZvxJC402vQSJkSZGcaQa5X3/OO1fa4zw0a3/14dsNDvdCwg78tFscw/LpaLO1Nq8ri+pUwH4+cZzO+Klly9MMaQPWlgP43TX/bhEiAlQLepO9q4okxmlJZB6LmOEAahCEJ4xL87C9L8PKPhBPu2dD7AUnOZWhX/qKwy50gMV8NtB0Pr+0uYBabSwFNjvrxn76yYsVg9IzeixyVJiBowyMg5dnNh22errz2UbL55Y3uMndI6v4yFPGsbPO+xOq4PV1pWLK/PE30xmVbvqeiiElHf6toFrJ2G4NKNa+xYhvNVvKyMWeRw45YpnyiEkckybCkRM4UZW0FHhsXxkI8AxBz3ingZtWkHZVV2P/M287aRq52vQ7FI2aNGEVyCejRWOM1tofCuH/DokfSeEDcstMch4r9rXW597ITDTd1tx7WBmyQ73qcHpVph41XYkx8lFGZMN5f0LPEyJyN8hW6Mugtf8M3EsHOvaeO4jcTfzarnFud47RoPDblcVxSaco7/MY5TwrNrAuaEBR0OCYjWYu2u831bZMKeWXHynnZCYALi0weU+waKWPCotlNK7RQYrf0LPerrg3xK8AwK2R6ka/rEgYc+f+3zYpUoG8yl1HqkkFbfLrKdAG7eSIjIpjUnFMfQIp9afvqrDHOkteMGufMe731DIU5fSdjBrvuRvLdqx9a3NnSl6J+xQsClPfKBlpx2yCCqRLNiofvkxLxRAYRfJWmPMFT9JUUcLd005FB/vp9VzSgBjdeVzBVy0r7lNfgQutG7RX/Fpzoo0roE9+/PIUtCe8iqeoMpH7mKnWEChxtSQTei7d9lVwrBVHEgvR00FsSSRyHRaiQJpFwKfjI28PVcywq8RAdjq5pqbRm9WmHnoFER0xXOJyXONQISsBA7h09vzkuVgyhMyieKuhsj64uvqV2SUEhtSWa/5Zy0caBgGlzIpOj3iIP9COVZy6u1hAcmeA8g3usEMLTLLa0QE/0G94AbyUHd/nKEbNi4kqo="

function App() {
  const url_string = window.location.href;
  const url = new URL(url_string);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramKey = Object.keys(params)[0]

  const lineId = (paramKey==='id') ? url.searchParams.get("id") : (!!localStorage.getItem('lineId')) ? localStorage.getItem('lineId') : ''


  const query = url.searchParams.get("query") || localStorage.getItem('query');
  const queryFinal = (paramKey==='id') ? null : query.split(' ').join('%2B')

  const windowLocation = 'https://vipwallet-sandbox.vivi-th.com';
  // const windowParentLocation = 'https://th.pmiandu.com/s/e-coupon-testing';
  // const windowParentLocation = window.parent.location.origin;

  const [scanData, setScanData] = useState('')
  const [selectedData, setSelectedData] = useState([])
  const [dataApi, setDataApi] = useState('')
  const [dataApiWs, setDataApiWs] = useState({})
  const [groupType, setGroupType] = useState('')

  const [error, setError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [message, setMessage] = useState('');
  const [modalErr, setModalErr] = useState(false)

  useEffect(() => {
    setError('')

    const fetchLogin =  async() => {
      let apiLocation = '';
      let apiXKey = ''
      let pathApi = '/ecp/api/user/login'
      let bodyData = { "query": queryFinal}

      // let windowParentLocation = ''

      // console.log('windowParentLocation',windowParentLocation);
      // console.log('window.location.origin',window.location.origin);
      // console.log('?query=',query);

      switch(window.location.origin) {
        // Local
        case "http://localhost:3000": 
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'http://localhost:3000';
          break;

        case "http://localhost:3001": 
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'http://localhost:3001';
          break;

        // Staging
        case "https://vipecoupon-sandbox.vivi-th.com":
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          // windowParentLocation = 'https://th.pmiandu.com/s/e-coupon-testing';
          break;

        // Production
        default:
          apiLocation = 'api.vipwallet.vivi-th.com';
          apiXKey = "756dc69c-4c3e-4724-aa3d-0cf27010c102";
          // windowParentLocation = 'https://th.pmiandu.com/s/e-coupon-testing';
          break;
      }

      if(!!lineId) {
        pathApi = '/ecp/api/NonDTE/login'
        bodyData = {"linemid": lineId}
        localStorage.setItem('accessBy','id')
      } else {
        localStorage.setItem('accessBy','query')
      }

      const res = await fetch(`https://${apiLocation}${pathApi}`, {
        method: 'POST',
        body: JSON.stringify(bodyData),
        headers: {
          "x-application-secret-key": apiXKey,
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });

      res
        .json()
        .then(res => {
          if (res.success === 0) {
            setModalErr(true);

          } else {
            const data = res;
            if (data.active_campaign === 0) {
              setMessage('คุณไม่ได้อยู่ใน Campaign');
              setModalErr(true);
              setIsLoaded(true);

            } else {
              // console.warn('login token',data.token)
              localStorage.setItem('accessToken', data.token);
              localStorage.setItem('userId', data.user);
              localStorage.setItem('query', query);
              // localStorage.setItem('windowParentLocation', data.campaign_data.widget_url);
              // localStorage.setItem('windowParentLocation',windowParentLocation)
              localStorage.setItem('windowLocation', windowLocation);
              localStorage.setItem('apiLocation', apiLocation);

              localStorage.setItem('windowParentLocation',(!!lineId)? '/' :data.parent_location)

              localStorage.setItem('lineId', lineId)
              
              localStorage.setItem('groupType', data.group_type)

              console.log(data)
              setDataApiWs(data)
              setDataApi(data);
              setGroupType(data.group_type);
              setIsLoaded(true);


            }
          }

        })
        .catch(err => {
          console.log(err);
          setModalErr(true)
          setMessage('ไม่สามารถเข้าระบบได้')
        });

    }

    fetchLogin()
  }, [lineId, paramKey, query, queryFinal])

  const handleCloseModal = () => {
    setModalErr(false)
    window.location.reload()
  }

  Modal.setAppElement('#root');

  if(modalErr) {
    return (<Modal
      isOpen={modalErr}
      className="_modal box-style app"
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)'
        }
      }
      }
    >
      <div className="_error-modal center">
        <p className="font-black">{message} {error}</p>
        <p className="font-black">กรุณาลองอีกครั้ง</p>
        <br />
        <ButtonRound text="กลับหน้าหลัก" color="blue" onClick={handleCloseModal} />
      </div>
    </Modal>)
  } else if (!isLoaded) {
    return (
      <div className="container loading">
        <div className="container-top">
          <h3>...Loading...</h3>
        </div>
      </div>
    );
  } else {

    return (
      <DataContext.Provider value={{scanData, setScanData, groupType, dataApi, selectedData, setSelectedData, dataApiWs, setDataApiWs}}>
        <div className="App">
          <Main />
  
          <Modal
            isOpen={modalErr}
            className="_modal box-style app"
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)'
              }
            }
            }
          >
            <div className="_error-modal center">
              <p className="font-black">{message} {error}</p>
              <p className="font-black">กรุณาลองอีกครั้ง</p>
              <br />
              
                <ButtonRound text="กลับหน้าหลัก" color="blue" onClick={handleCloseModal} />
            </div>
          </Modal>
        </div>
      </DataContext.Provider>
    );
  }

}

export { DataContext }
export default App;
