import React from 'react'

function Button3D(props) {
    const { text, onClick } = props
    return (
        <button className="btn-ellipse _3d bg-gradient-blue btn-fix-bottom" onClick={onClick}>
            <h3 className="font-white">{ text }</h3>
        </button>
    )
}

export default Button3D
