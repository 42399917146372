import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/react-hooks";
import { Redirect } from 'react-router-dom';

const USERS_ADDED_SUBSCRIPTION = gql`
  subscription OnUserAdded {
    added {
      id
      name
      qr_id
      event_id
    }
  }
`;

export function EventSubscription() {
  const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);

  if (loading) return null;
  if (error) {
    console.log(`Error! ${error.message}`);
    return false
  }

  if (!loading && data) {
    const { added } = data;
    let userId = localStorage.getItem('userId');

    console.log('added.id',data.added.id,', user id',userId)

    if(data.added.id === userId ) {
      console.log('QraphQL:',added)
      localStorage.setItem('qrcodeID',data.added.qr_id)
      localStorage.setItem('eventID',data.added.event_id)
      localStorage.setItem('name',data.added.name)
      console.log('QraphQL Redirect: Success')
      return <Redirect to="/home" />
    } else {
      console.log('QraphQL Redirect: FAILED')
      return false
    }

    // return (<div>ID incorrect...</div>);
  } else {
    return (<h4>Listening...</h4>);
  }
}

export function SampleQuery(client) {
  client
    .query({
      query: gql`
        query Info {
          numberSix
          numberSeven

          user(id: 1) {
            id
            name
            createdAt
            updatedAt
          }

          users {
            id
            name
            createdAt
            updatedAt
          }
        }
      `,
    })
    .then((result) => console.log(result))
    .catch((err) => console.error(err));
}
