import React from 'react'

function ButtonRound(props) {
    const { text, color, onClick, style } = props
    return (
        <button className={`btn-round bg-gradient-`+color} onClick={onClick} style={style}>
            <h3 className="font-white">{ text }</h3>
        </button>
    )
}

export default ButtonRound
