import React, { useState, useEffect } from 'react'
import moment from 'moment';

import BgCouponDisable from '../assets/img/bg-coupon-uncollected.png';
import BgCouponDisablePoint from '../assets/img/bg-coupon-uncollected-point-blue.png';
import BgSelectInvisible from '../assets/img/ic-select-invisible.png';

function CouponClickToCollectCard(props) {
    const { dataItem, dataGroupType, onClick, childRef } = props
    const [windowW, setWindowW] = useState(window.innerWidth)

    const resizeWindow = () => {
        setWindowW(window.innerWidth)
    }


    let BgImage = ''
    let BgIconInvisible = ''

    useEffect(() => {
        window.addEventListener("resize", resizeWindow);
        // console.log(dataItem)
        return () => {
            resizeWindow()
        }
    }, [windowW])

    BgImage = dataItem.ecp_status === 'uncollected' ? BgCouponDisable : BgCouponDisablePoint
    BgIconInvisible = BgSelectInvisible
    // BgImagePoint = BgCouponDisablePoint

    function diffDate(endDateVal) {
        const end = moment(endDateVal).format("DD.MM.YYYY")
        const current = moment().format("DD.MM.YYYY")

        const endDate = moment(end, "DD.MM.YYYY");
        const currentDate = moment(current, "DD.MM.YYYY");
        // console.log(endDate,' : ',currentDate)

        const valueDiffDate = endDate.diff(currentDate, 'days')
        // console.log(valueDiffDate)
        
        return valueDiffDate + 1;
        
    }

    return (
        <div id={dataItem.ecp_id} ref={childRef} className={`couponcard _${dataItem.ecp_status === 'uncollected'? 'orange' : 'blue'}`} style={{backgroundImage: 'url('+BgImage+')', backgroundSize: 'cover', /*minHeight: (windowW/4.37)+'px' */}} onClick={onClick}>
            <div className="light" style={{width: '200px', height: '140px'}}></div>
            <div className="_content">
                <div className="_row">
                    <div className="_left">
                        <p className="subheadertext">{
                            dataItem.ecp_status === 'uncollected' ? 
                                dataGroupType === 'gt' ? 'คูปองส่วนลด' /*'ใช้กับร้านส่ง'*/ : dataGroupType === 'ws' ? 'คูปองส่วนลด' : 'คูปองส่วนลด'
                            :
                                'คูปอง VIP'
                        }</p>
                        <p className="subtext">
                            {
                                dataItem.ecp_status === 'uncollected' ? ' ' : `ใช้ ${(dataItem.sf_point).toLocaleString('en-US')} คะแนนเพื่อเก็บคูปอง`
                            }
                        </p>
                    </div>
                    <div className="_right">
                        <img src={BgIconInvisible} alt="icon" />
                    </div>
                </div>
                <div className="_row">
                    <div className="_left">
                    <h1 data-text={
                        dataGroupType === 'ws' ? 
                        !!dataItem.ecp_value ? dataItem.ecp_value.toLocaleString('en-US') : 0
                        :
                        !!dataItem.face_value ? dataItem.face_value.toLocaleString('en-US') : 0
                    }>{
                            dataGroupType === 'ws' ? 
                            !!dataItem.ecp_value ? dataItem.ecp_value.toLocaleString('en-US') : 0
                            :
                            !!dataItem.face_value ? dataItem.face_value.toLocaleString('en-US') : 0
                            // (dataGroupType === 'ws' && dataItem.ecp_status === 'used') || (dataGroupType === 'ws' && dataItem.ecp_status === 'redeemed') ? 
                            //     !!(dataItem.face_value + dataItem.topup) ? (dataItem.face_value + dataItem.topup).toLocaleString('en-US') : 0
                            //     :
                            //     !!dataItem.face_value ? dataItem.face_value.toLocaleString('en-US') : 0
                        } <span>บาท</span></h1>
                    </div>
                    <div className="_right">
                        {/* <p>รหัส {dataItem.ecp_id}</p> */}
                        <button className='btn-capsule'>
                            <h3>
                                {
                                    dataItem.ecp_status === 'uncollected'? 'เก็บ' : dataItem.sf_point > 0 ? 'ใช้คะแนนแลก' : 'เก็บ'
                                }
                            </h3>
                        </button>
                    </div>
                </div>
                <div className="_row">
                    <p className="notice">เก็บคูปองภายใน {diffDate(dataItem.coupon_collection_expiration)} วันเท่านั้น</p>
                </div>
            </div>
        </div>
    )
}

export default CouponClickToCollectCard
