import React, { useState, useEffect} from 'react'
import moment from 'moment';

import BgCouponRedDefault from '../assets/img/bg-coupon-default.png';
import BgCouponRedDefaultVip from '../assets/img/bg-coupon-default-vip.png';
import BgSelectIcon from '../assets/img/ic-selected.png';
// import BgSelectIconInvisible from '../assets/img/ic-select-invisible.png';


function CouponRedCard(props) {
    const { id, dataItem, dataGroupType, selected, onClick } = props

    // const [selected, setSelected] = useState(false)
    // const windowWidth = window.innerWidth;
    const [windowW, setWindowW] = useState(window.innerWidth)

    const resizeWindow = () => {
        setWindowW(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", resizeWindow);
        return () => {
            resizeWindow()
        }
    }, [])

    let BgImage = ''
    let BgIcon = ''
    // let BgIconInvisible = ''

    BgImage = dataItem.click_to_collect === 1 && dataItem.sf_point > 0 ? BgCouponRedDefaultVip : BgCouponRedDefault
    BgIcon = BgSelectIcon
    // BgIconInvisible = BgSelectIconInvisible

    function showEndDate(endDateVal) {
        const monthThArr = ['มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];
        const end = endDateVal
        
        const endD = moment(end).format('DD');
        const endM = moment(end).format('M');
        const endY = moment(end).format('YYYY');
        const endTime = moment(end).format(' HH:mm')

        const valueEnddate = endD+' '+monthThArr[endM - 1]+' '+(Number(endY)+543)+' '+endTime;
        
        return valueEnddate;
    }

    

    return (
        <div id={id} className={selected ? 'couponcard _red _selected' : 'couponcard _red '} style={{ backgroundImage: 'url('+BgImage+')', minHeight: (windowW/2.87)+'px' }} onClick={onClick}>
            <div className="_content">
                <div className="_row">
                    <div className="_left">
                        <h3>{
                            // dataGroupType === 'gt' ? 'คูปองส่วนลด' /*'ใช้กับร้านส่ง'*/ : dataGroupType === 'ws' ? 'คูปองส่วนลด' : 'คูปองส่วนลด'
                            dataItem.click_to_collect === 1 && dataItem.sf_point > 0 ? 'คูปอง VIP' : 'คูปองส่วนลด'
                        }</h3>
                    </div>
                    <div className="_right">
                        <img id={`icon-check-${id}`} className="icon-check" src={BgIcon} alt="icon" />
                        {/* {
                            selected ? 
                                <img id={`icon-check-${id}`} className="icon-check" src={BgIcon} alt="icon" />
                            : 
                                <img id={`icon-uncheck-${id}`} className="icon-check" src={BgIconInvisible} alt="icon" />
                        } */}
                    </div>
                </div>
                {
                    (!!dataItem.wording && dataGroupType === 'gt') || (!!dataItem.wording && dataGroupType === 'ws' && dataItem.ecp_status === 'unused') ?
                    <div className="_row">
                        <div className="_left" style={{whiteSpace: 'nowrap'}}>
                            {dataItem.wording}
                            {/* <h3>ทดสอบขึ้นขั้นตอนสูงสุดเพิ่มข้อความ</h3> */}
                        </div>
                        <div className="_right"></div>
                    </div>
                    : null
                }
                <div className="_row">
                    <div className="_left">
                        <h1>{!!(dataItem.face_value) ? (dataItem.face_value).toLocaleString('en-US') : 0} <span>บาท</span></h1>
                    </div>
                    <div className="_right">
                        <p>รหัส {dataItem.ecp_id}</p>
                    </div>
                </div>
            </div>
            <div className="_bottom">
            {
                    dataItem.end_date === null || dataItem.end_date === '' ? <p>&nbsp;</p> 
                    :
                    dataItem.ecp_status === 'used' && dataGroupType === 'ws' ? null 
                    :
                    <p>
                        {dataItem.ecp_status === 'unused' || dataItem.ecp_status === 'waiting-to-redeem' || dataItem.ecp_status ==='waiting-for-confirmation' ? 'ใช้ได้จนถึงวันที่' : ''} 
                        {dataItem.ecp_status === 'unused' || dataItem.ecp_status === 'waiting-to-redeem' || dataItem.ecp_status ==='waiting-for-confirmation' ? showEndDate(dataItem.end_date) : ''}
                    </p>
                    
                    // <p>{dataItem.ecp_status === 'unused' || dataItem.ecp_status === 'used' || dataItem.ecp_status === 'waiting-to-redeeme' || dataItem.ecp_status === 'waiting-for-confirmation' ? 'ใช้ได้จนถึงวันที่' : ''} {showEndDate(dataItem.end_date)}</p>
                    // <p>{dataItem.contentbottom} {moment(dataItem.date).format("DD MMMM YYYY")}</p>
                }
            </div>
        </div>
    )
}

export default CouponRedCard
