import React, { useState, useContext } from 'react'
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route } from 'react-router-dom';

import ApolloClient from "../graphql/client";
import { EventSubscription } from "../components/EventSubscription";
// import { UserAdded } from "../components/UserSubscription";

import Home from './Home';
// import moment from 'moment';
import Modal from 'react-modal';
import SelectCoupon from './SelectCoupon';
import SelectCouponConfirm from './SelectCouponConfirm';

import ButtonRound from '../components/ButtonRound';
import ScanCoupon from './ScanCoupon';

import { DataContext } from '../App';


function Main() {
    const { groupType } = useContext(DataContext)
    // const history = useHistory()
    const [confirm, setConfirm] = useState(false)

    const closeModal = () => {
        setConfirm(false)
        // history.push('/')
        window.location = '/';
    }

    return (
        <div className="container" style={groupType==='ws'? null : {minHeight: window.innerHeight - 85, overflow: 'auto' } }>
            <ApolloProvider client={ApolloClient}>
                <BrowserRouter>
                    <EventSubscription />
                    {/* <UserAdded />  */}
                    
                    <Route exact path="/" render={()=> <Home /> } />
                    <Route exact path="/Home" render={()=> <Home /> } />
                    <Route path="/selectcoupon" render={()=> <SelectCoupon /> } />
                    <Route path="/selectcouponconfirm" render={()=> <SelectCouponConfirm /> } />
                    <Route path="/scancoupon" render={()=> <ScanCoupon /> } />

                    <Modal 
                        isOpen={confirm}
                        className="_modal box-style modal-selectcoupon" 
                        style={{ 
                            overlay: {
                                backgroundColor: 'rgba(0,0,0,0.5)'
                            }}
                        }
                    >
                        <div className="modal-content txt-center">
                            <h3>คุณได้แลกคูปอง ดังต่อไปนี้</h3>
                            <ul className="modal-coupon-list">
                                <li>
                                    <h3>1. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                                <li>
                                    <h3>2. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                                <li>
                                    <h3>3. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                                <li>
                                    <h3>4. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                                <li>
                                    <h3>5. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                                <li>
                                    <h3>6. คูปอง 50 บาท</h3>
                                    <p>(หมดอายุ 30 สิงหาคม 2564)</p>
                                </li>
                            </ul>
                            <p className="font-black">เป็นส่วนลดมูลค่า <b>X,XXX</b> บาท<br/>กับคุณ XXX เรียบร้อยแล้ว</p>
                            <br/>
                            <div className="btn-fluid txt-center">
                                <ButtonRound color="blue" text="กลับหน้าหลัก" onClick={closeModal} />
                            </div>

                        </div>
                    </Modal>

                </BrowserRouter>
            </ApolloProvider>
        </div>
    )
}

export default Main
